// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseEndPoint: "https://stagingapi.idashyou.ng/",
  appUrl: "https://staging.idashyou.ng/",
  paystackSecret: "pk_test_32623760f0fa3e05160c46decf34f6b8486d5a51",
  paystackCurrency: "NGN",
  monnifyApiKey: "MK_TEST_FPF6523RFU",
  monnifyContractCode: "6285342640",
  monnifyCurrency: "NGN",
  siteAccessToken: "jdjdjdjncncjcjjcjcjcjc",
  mobileApp: false,
  ismobileWeb: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
